import "./ExamStructure.css";
import ExpandCollapseButtons from "src/components/layout/expand-collapse/ExpandCollapseButtons";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import { ExamStructureContext } from "src/pages/goodpoint/assessment/components/structure/ExamStructureContext";
import { useVisibility, Visibility, VisibilityContext } from "src/pages/goodpoint/assessment/components/structure/Visibility";
import IconButton from "src/components/ui/IconButton";
import { Check, ListSelect, Xmark } from "iconoir-react";
import SearchBar from "src/components/content/SearchBar";
import { useContext, useRef, useState } from "react";


function ShowHideButton({ isSelectionMode, setIsSelectionMode }) {
    const {clear, hiddenCount} = useContext(VisibilityContext);
    return (
        <div className="flex-row align-center gap-mid">
            <IconButton
                Icon={isSelectionMode ? Check : ListSelect}
                text={isSelectionMode ? "Finish" : "Select visible"}
                borderSize="0"
                bgColour={isSelectionMode ? "var(--orange-mid)" : "var(--orange-light)"}
                paddingSize="var(--padding-small)"
                onClick={() => setIsSelectionMode(mode => !mode)}
            />
            {
                (hiddenCount > 0) && (!isSelectionMode) &&
                    <div 
                        className="clear-hidden fg-orange-dark flex-row align-center cursor-pointer"
                        onClick={() => clear()}
                    >
                        <p>{hiddenCount} total hidden (Clear)</p>
                        <Xmark strokeWidth={1.0} />
                    </div>
            }
        </div>
    )
}

export function ExamStructure({
    structureMap, HeaderComponent, ItemComponent,
    itemCommonProps={}, initiallyExpanded=false
}) {
    const expandCollapse = useExpandCollapse(initiallyExpanded);
    const visibilityContextValue = useVisibility(structureMap);
    const {clear, visibilityMap} = visibilityContextValue;
    const countHiddenQuestions = Object
        .values(visibilityMap)
        .filter(q => q.visibility in [Visibility.HIDDEN, Visibility.PARTIALLY_HIDDEN])
        .length;

    const searchVisibility = useVisibility(structureMap);
    const {clear: clearSearchVisibility} = searchVisibility;

    const [isSelectionMode, setIsSelectionMode] = useState(false);

    const [searchTerms, setSearchTerms] = useState([]);

    const clearSearchBar = useRef(() => {});

    function onChangeSearchText(text) {
        const newSearchTerms = text.split(",").map(s => s.trim()).filter(s => s.length > 0);
        setSearchTerms(newSearchTerms);
    }

    function clearSearchTerms() {
        clearSearchVisibility();
        setSearchTerms([]);
        setIsSelectionMode(false);
    }

    return (
        <ExpandCollapseContext.Provider value={expandCollapse}>
        <ExamStructureContext.Provider value={{ 
            structureMap,
            ItemComponent, 
            isSelectionMode, 
            itemCommonProps,
            searchTerms,
            clearSearch: () => clearSearchBar.current(),
            searchVisibility
        }}>
        <VisibilityContext.Provider value={visibilityContextValue}>
            <div className="exam-structure">
                <div className="flex-col gap-large">
                    <HeaderComponent structureMap={structureMap} />
                    <div className="exam-structure-header flex-row justify-content-space-between">
                        <div className="flex-row gap-large align-center">
                            <SearchBar clearRef={clearSearchBar} onChangeSearchText={onChangeSearchText} onClear={clearSearchTerms} />
                            <ShowHideButton isSelectionMode={isSelectionMode} setIsSelectionMode={setIsSelectionMode} />
                        </div>
                        <ExpandCollapseButtons />
                    </div>
                </div>
                <div className="structure-content flex-col gap-mid">
                    {
                        (countHiddenQuestions > 0) && (!isSelectionMode) &&
                            <div 
                                className="clear-hidden fg-orange-dark flex-row align-center cursor-pointer"
                                onClick={() => clear()}
                            >
                                <p>{`${countHiddenQuestions} hidden question${(countHiddenQuestions > 1) ? "s" : ""} (Clear)`}</p>
                                <Xmark strokeWidth={1.0} />
                            </div>
                    }
                    {
                        Object
                            .values(structureMap)
                            .map((itemData, index) => 
                                <ItemComponent 
                                    key={index}
                                    itemData={itemData}
                                    outerTags={[]}
                                />
                            )
                    }
                </div>
            </div>
        </VisibilityContext.Provider>
        </ExamStructureContext.Provider>
        </ExpandCollapseContext.Provider>
    )
}