import { useContext } from "react";
import Title from "src/components/content/Title";
import Divider from "src/components/layout/Divider";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import ClassStats from "src/pages/goodpoint/assessment/tabs/grading/class-performance/ClassStats";
import DownloadOverallFeedback from "src/pages/goodpoint/assessment/tabs/grading/class-performance/question-feedback/DownloadOverallFeedback";
import QuestionFeedback from "src/pages/goodpoint/assessment/tabs/grading/class-performance/question-feedback/QuestionFeedback";


export default function ClassPerformance({ examResults }) {
    const { assessmentObject } = useContext(AssessmentContext);
    return (
        <div className="results-content fill-width fill-height flex-col gap-large">
            <ClassStats examResults={examResults} />
                <div className="column grading">
                <Title
                    Size="b"
                    title="Question Feedback"
                    subtitle=""
                />
                <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)'/>
                <p className="font-size-small">
                    Feedback for each question is aggregated from the performance of all the students in the class. 
                    To view a single student's feedback, click on the "Student Performance" button above.
                </p>
                <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)'/>
                <QuestionFeedback />
                <div style={{marginTop: "20px"}}>
                    <DownloadOverallFeedback assessmentId={assessmentObject.id}/>
                </div>
            </div>
        </div>
    )
}