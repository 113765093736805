import { useContext, useEffect } from "react";
import Latex from "react-latex-next";
import StructureSection from "src/pages/goodpoint/assessment/components/structure/StructureSection";

import "./QuestionFeedbackItem.css"
import { calculatePercentage } from "src/util/StringUtil";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import ExpandCollapseButtons from "src/components/layout/expand-collapse/ExpandCollapseButtons";
import { NavArrowDown, NavArrowRight } from "iconoir-react";
import { StudentsContext } from "src/pages/goodpoint/assessment/tabs/answers/StudentsContext";
import { StructureItem } from "src/pages/goodpoint/assessment/components/structure/StructureItem";
import { ExamStructureContext } from "src/pages/goodpoint/assessment/components/structure/ExamStructureContext";


function QuestionStat({ text=null, textComponents=undefined, values, valuesStyle="fg-orange-dark" }) {
    return <div className="flex-row justify-content-space-between align-center">
        {
            text && <p>{text}</p>
        }
        {
            textComponents
        }
        <div className="flex-row gap-mid">
            {
                values.map((value, index) => <p key={index} className={valuesStyle}>{value}</p>)
            }
        </div>
    </div>
}

function ExpandableQuestionStat({ text, values, valuesStyle="fg-orange-dark", children }) {
    const {
        isExpanded: isAllExpanded,
        isCollapsed: isAllCollapsed,
        reset: resetAll
    } = useContext(ExpandCollapseContext);

    const {isExpanded, expand, collapse, toggle} = useExpandCollapse();
    
    useEffect(() => { isAllExpanded && expand(); },    [isAllExpanded, expand]);
    useEffect(() => { isAllCollapsed && collapse(); }, [isAllCollapsed, collapse]);

    return <>
        <div 
            className="flex-row justify-content-space-between cursor-pointer"
            onClick={() => { toggle(); resetAll(); }}
        >
            <div className="flex-row gap-small">
                {
                    isExpanded 
                        ? <NavArrowDown color="var(--orange-dark)" width={"1em"}/> 
                        : <NavArrowRight color="var(--orange-dark)" width={"1em"}/>
                }
                <p>{text}</p>
            </div>
            <div className="flex-row gap-mid">
                {
                    values.map((value, index) => <p key={index} className={valuesStyle}>{value}</p>)
                }
            </div>
        </div>
        <div className="question-stat-content">
            {
                isExpanded && children
            }
        </div>
    </>
}

function StudentChangeButton({ studentId }) {
    const {setSelectedStudent} = useContext(StudentsContext);
    return <button className="student-change-button clickable bg-yellow-light rounded-small" onClick={() => {setSelectedStudent(studentId)}}>
        {studentId}
    </button>
}


function QuestionFeedbackItemHeader({ itemData, nestingLevel }) {
    return <div className="question-feedback-item-header fill-width flex-row justify-content-space-between">
        <p className="question-feedback-item-question-tag justify-self-left">
            <b>{(nestingLevel > 0) ? "Part " : ""}{itemData.tag}</b>
        </p>
        {
            <div className="flex-row gap-mid">
                <p>Average:</p>
                <p>{Math.round((itemData.avg_mark ?? 0) * 10) / 10}/{itemData.max_marks}</p>
                <p>({calculatePercentage(itemData.avg_mark ?? 0, itemData.max_marks)}%)</p>
                <p>{`Grade ${itemData.avg_grade}`}</p>
            </div>
        }
    </div>
}

function QuestionFeedbackItemContent({ itemData, nestingLevel=0 }) {
    const expandCollapse = useExpandCollapse(false);

    const studentsAttempted = itemData.students_correct
        .map((student_id) => { 
            return { 
                grade: itemData.highest_grade, 
                marks: itemData.max_marks, 
                student_id: student_id 
            }
        })
        .concat(itemData.students_attempted);

    return <div className="feedback-content">
        <ExpandCollapseContext.Provider value={expandCollapse}>
            <StructureSection 
                heading={"Statistics"} 
                headingControls={
                    <ExpandCollapseButtons
                        props={{
                            iconSize: 17, 
                            borderSize: "var(--border-thin)", 
                            paddingSize: "0px 5px",
                            roundedSize: "var(--rounded-xsmall)"
                        }}
                    />
                }
                initiallyExpanded={nestingLevel === 0} 
                aiGenerated
            >
                <div className="question-feedback-stats flex-column gap-mid">
                    <QuestionStat text="Average marks" values={
                        [
                            `${(Math.round(itemData.avg_mark * 10) / 10)}/${itemData.max_marks}`, 
                            `(${calculatePercentage(itemData.avg_mark ?? 0, itemData.max_marks)}%)`,
                            `Grade ${itemData.avg_grade}`
                        ]
                    } valuesStyle="fg-orange-dark"/>
                    <ExpandableQuestionStat text="Highest mark" values={
                        [
                            `${(Math.round(itemData.highest_mark * 10) / 10)}/${itemData.max_marks}`, 
                            `(${calculatePercentage(itemData.highest_mark ?? 0, itemData.max_marks)}%)`,
                            `Grade ${itemData.highest_grade}`
                        ]
                    } valuesStyle="fg-orange-dark">
                        {
                            itemData.highest_students.map((student_id, index) => 
                                <StudentChangeButton key={index} studentId={student_id} />
                            )
                        }
                    </ExpandableQuestionStat>
                    <ExpandableQuestionStat text="Lowest mark" values={
                        [
                            `${(Math.round(itemData.lowest_mark * 10) / 10)}/${itemData.max_marks}`, 
                            `(${calculatePercentage(itemData.lowest_mark ?? 0, itemData.max_marks)}%)`,
                            `Grade ${itemData.lowest_grade}`
                        ]
                    } valuesStyle="fg-orange-dark">
                        {
                            itemData.lowest_students.map((student_id, index) => 
                                <StudentChangeButton key={index} studentId={student_id} />
                            )
                        }
                    </ExpandableQuestionStat>
                    <ExpandableQuestionStat text="Attempted by" values={
                        [
                            `${studentsAttempted.length} students`
                        ]
                    } valuesStyle="fg-orange-dark">
                        <ul>
                            {
                                studentsAttempted.map((student, index) => 
                                    <QuestionStat 
                                        key={index}
                                        textComponents={<StudentChangeButton key={index} studentId={student.student_id} />}
                                        values={[
                                            `${(Math.round(student.marks * 10) / 10)}/${itemData.max_marks}`, 
                                            `(${calculatePercentage(student.marks ?? 0, itemData.max_marks)}%)`,
                                            `Grade ${student.grade}`
                                        ]}
                                        valuesStyle="fg-grey"
                                    />
                                )
                            }
                        </ul>
                    </ExpandableQuestionStat>
                </div>
            </StructureSection>
        </ExpandCollapseContext.Provider>
        {
            itemData.question_feedback &&
                <StructureSection heading={"Feedback"} aiGenerated>
                    <Latex>{itemData.question_feedback}</Latex>
                </StructureSection>
        }
    </div>
}

export default function QuestionFeedbackItem({ itemData, ...props }) {
    const {isFilterOnlyMistakes} = useContext(ExamStructureContext).itemCommonProps;
    if (isFilterOnlyMistakes && !(itemData.avg_mark < itemData.max_marks)) {
        return null;
    }

    return <StructureItem
        itemData={itemData}
        HeaderComponent={QuestionFeedbackItemHeader} 
        ContentComponent={QuestionFeedbackItemContent}
        {...props}
    />;
}